class Config {
  constructor () {
    this.graphBackend = 'https://www.agri-trace-sys-graph.chbio.info/'
    this.basicBackendBaseURL = 'https://www.agri-trace-sys-blockchain.chbio.info/'
    this.appBackendBaseURL = 'https://www.agri-trace-sys-app.chbio.info/'
    this.recordGetMaxAmountBasedOnBackendSet = 10
  }
}

export default new Config()
