import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/style.css'
import VueSimpleAlert from 'vue-simple-alert'
import VueQriously from 'vue-qriously'
document.title = '正瀚智慧農產履歷-Monitor'
Vue.use(VueQriously)
Vue.use(VueSimpleAlert)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
